/*

# BEM (BLOCK, ELEMENT, MODIFIER) METHEDOLOGY

<div class="card card--show">
  <div class="card__title"></div>
  <div class="card__container">

  </div>
</div>

.card - BLOCK

.card__title - ELEMENT

.card--show - MODIFIER

*/

/* RESET styles */

*,
*::after,
*::before {
	box-sizing: border-box;
}

html,
body,
p {
	margin: 0;
	padding: 0;
}

a {
	color: #546e7a;
}

ul,
li {
	list-style: none;
	padding: 0;
	margin: 0;
}

.no--select {
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;
	user-select: none;
}

h3 {
	text-align: left;
	margin-top: 20px;
	margin-bottom: 30px;
	font-weight: 500;
}

/* MAIN styles */

body {
	font-family: Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-size: 14px;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	scroll-behavior: smooth;
}

.app-layout {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
}

header {
	position: fixed;
	width: 100%;
	height: 56px;
	top: 0;
	background-color: #546e7a;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.19);
	display: flex;
	overflow: hidden;
	z-index: 1;
	color: #fff;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	transition: background-color 250ms linear;
}

.app__offline {
	background-color: #6b6b6b;
}

.header__icon {
	width: 48px;
	height: 48px;
	margin: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.header__icon:active {
	opacity: 0.8;
	outline: 1px solid #fff;
}

.header__title {
	color: #fff;
	font-size: 20px;
	-ms-grid-row-align: center;
	align-self: center;
	margin-left: 10px;
}

.menu {
	width: 280px;
	height: 100%;
	background: #fff;
	position: fixed;
	top: 0;
	bottom: 0;
	box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.4);
	z-index: 1;
	transition: -webkit-transform 0.3s cubic-bezier(0, 0, 0.3, 1);
	transition: transform 0.3s cubic-bezier(0, 0, 0.3, 1);
	transition: transform 0.3s cubic-bezier(0, 0, 0.3, 1), -webkit-transform 0.3s cubic-bezier(0, 0, 0.3, 1);
	-webkit-transform: translateX(-110%);
	transform: translateX(-110%);
	will-change: transform;
	z-index: 2;
}

.menu--show {
	-webkit-transform: translateX(0);
	transform: translateX(0);
}

.menu__overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.3);
	transition: opacity 0.15s cubic-bezier(0, 0, 0.3, 1);
	visibility: hidden;
	opacity: 0;
	z-index: 1;
}

.menu__overlay--show {
	visibility: visible;
	opacity: 1;
}

.menu__header {
	height: 150px;
	background: #546e7a;
	color: #fff;
	border-bottom: 1px solid #ddd;
}

.menu__list {
	width: inherit;
	height: inherit;
	overflow: auto;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	background-color: #f5f5f6;
}

.menu__list li {
	border: 0;
	padding: 0;
	box-shadow: none;
	border-radius: 0;
}

.menu__list li a {
	padding: 20px;
	color: rgba(0, 0, 0, 0.87);
	cursor: pointer;
	display: block;
}

.menu__list li a:active,
.menu__list li a:hover {
	background: #e7e7e7;
}

.app__content {
	width: 320px;
	height: 100%;
	margin: 0 auto;
	margin-top: 56px;
	padding-top: 10px;
}

.toast__msg {
	max-width: 290px;
	min-height: 50px;
	line-height: 50px;
	color: #fff;
	padding-left: 10px;
	padding-right: 10px;
	text-transform: initial;
	margin-bottom: 10px;
	background-color: #404040;
	border-radius: 3px;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
	word-break: break-all;
	font-size: 15px;
	font-weight: 400;
	opacity: 0;
	-webkit-transform: translateY(20px);
	transform: translateY(20px);
	will-change: transform;
	position: fixed;
	bottom: 20px;
	left: 20px;
}

.toast__msg--show {
	opacity: 1;
	-webkit-transform: translateY(0);
	transform: translateY(0);
}

button {
	min-width: 90px;
	height: 35px;
	font-size: 14px;
	border: 0;
	background: #4f8efa;
	color: #fff;
	margin: 0 auto -5px;
	display: inline-block;
	cursor: pointer;
	outline: 0;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.38);
	-webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.38);
	-moz-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.38);
	-o-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.38);
	user-select: none;
	border-radius: 4px;
}

button:active {
	box-shadow: none;
}

button:disabled {
	background: #ccc;
	color: #000;
	cursor: not-allowed;
}

.custom__button p {
	position: initial;
	margin: 0;
	padding-left: 10px;
}

.custom__button {
	padding: 10px 15px;
	font-family: "Roboto", arial, sans-serif;
	text-align: left;
}

.turn-on-sync {
	min-width: 75px;
	height: 30px;
	margin-left: 10px;
}

.custom__input:checked + .custom__checkbox {
	background: rgb(195, 195, 195);
}

.custom__input:checked + .custom__checkbox::before {
	left: 25px;
	background: #0288d1;
}

.card__container {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
}

.card {
	width: 320px;
	min-height: 280px;
	background: #fff;
	margin: 20px auto;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	position: relative;
	border: 1px solid #e6e6e6;
}

.card__title,
.card__desc {
	display: block;
	font-size: 14px;
	text-align: center;
}

.card__title {
	margin-left: 5px;
	font-weight: 500;
}

.card__temp {
	padding: 20px;
	padding-bottom: 10px;
}

.card__temp span {
	font-size: 14px;
}

.card__following,
.card__followers {
	padding: 10px 20px 5px;
}

.card__desc {
	padding: 12px 15px;
	vertical-align: top;
}

.card__img {
	width: 60px;
	height: 60px;
	display: block;
	margin: 20px auto 10px;
	border-radius: 50%;
}

b {
	font-family: inherit;
	font-weight: 500;
}

.card b {
	margin-right: 5px;
}

.card__temp,
.card__followers,
.card__following {
	display: flex;
	flex-direction: row;
	margin-bottom: 5px;
}

.card__followers {
	margin-bottom: 20px;
}

.fab {
	width: 56px;
	height: 56px;
	background: #546e7a;
	border-radius: 50%;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: fixed;
	bottom: 0;
	right: 0;
	margin: 25px;
	-webkit-tap-highlight-color: transparent;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	overflow: hidden;
}

.fab.active {
	background: #faab1a;
}

.fab__ripple {
	position: absolute;
	left: -17px;
	bottom: -12px;
	width: 56px;
	height: 56px;
	-webkit-transform: scale(0.5);
	transform: scale(0.5);
	background: #fff;
	border-radius: 50%;
	-webkit-transform-origin: 50%;
	transform-origin: 50%;
	transition: -webkit-transform 0.35s cubic-bezier(0, 0, 0.3, 1) 0ms;
	transition: transform 0.35s cubic-bezier(0, 0, 0.3, 1) 0ms;
	transition: transform 0.35s cubic-bezier(0, 0, 0.3, 1) 0ms, -webkit-transform 0.35s cubic-bezier(0, 0, 0.3, 1) 0ms;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	will-change: transform;
	z-index: 2;
	opacity: 0;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.fab:active .fab__ripple {
	opacity: 0.2;
	-webkit-transform: scale(1) translate(31%, -22%);
	transform: scale(1) translate(31%, -22%);
}

.fab__image {
	overflow: hidden;
	z-index: 3;
}

.add__card {
	margin: 40px auto;
	text-align: center;
}

.add__input {
	width: 210px;
	height: 35px;
	border: 1px solid #ccc;
	padding-left: 10px;
	font-size: 14px;
	display: block;
	margin: 10px auto;
	border-radius: 4px;
}

.add__btn {
	height: 34px;
	min-width: 70px;
	margin-top: 10px;
	display: block;
	margin-left: 0;
}

.add__card ul,
.add__card li,
.share__container li {
	width: 320px;
	text-align: left;
	margin: 15px auto;
}

.add__card p {
	font-weight: 500;
	font-size: 18px;
	margin-top: 40px;
}

.card span {
	display: block;
}

.add__to-card {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
}

.bg-sync__text {
	font-size: 12px;
	padding-left: 5px;
	color: #008000;
}

.custom__button.custom__button-bg {
	padding: 0;
	margin: 0;
	display: inline-block;
}

.custom__button.custom__button-bg.hide {
	display: none;
}

b i a {
	text-decoration: underline;
	color: #546e7a;
}

.add__card ul + p {
	margin-top: 20px;
}

.card__spinner {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto;
	background: rgba(0, 0, 0, 0.16);
	display: none;
}

.card__spinner::after {
	content: "Loading...";
	color: #546e7a;
	background: #fff;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
	margin: auto;
	text-align: center;
	line-height: 380px;
	font-size: 18px;
}

.card__spinner.show {
	display: block;
}

.share__container a {
	text-decoration: underline;
	color: #546e7a;
}

.share__container {
	margin-bottom: 50px;
}

.share {
	margin: 20px auto;
	text-align: center;
	display: block;
}

li {
	border: 1px solid #e6e6e6;
	padding: 10px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

h4 {
	text-align: left;
	margin-bottom: 30px;
}
